import React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import get from 'lodash/get';
import Typography from 'components/uiLibrary/Typography';
import ActionCard from 'components/uiLibrary/ActionCard';
import { enqueueSnackbar, SNACKBAR_VARIANTS } from 'components/uiLibrary/Snackbar';
import {
  SECTIONS,
  SUB_COMPONENTS,
  ACTION_CALLBACK_STEPS,
  ACTION_CALLBACK_STATUS,
} from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';
import { registrationPayload } from 'containers/Accounts/utils';
import { REGISTRATION_STEPS, REGISTRATION_COHORT_TYPES } from 'containers/Accounts/constants';
import { useCreateRegistrationMutation } from 'containers/Accounts/queries';
import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import usePageContext from 'utils/hooks/usePageContext';
import { nextPageQueryName } from 'utils/auth';
import { errorMessageConverter } from 'utils/common';

import { useTranslation } from 'src/i18n';

import { TP, BASE_PAGE_ROUTES } from 'constants/index';
import classes from './ProfileClaim.module.scss';

const trackingData = {
  section: SECTIONS.PROFILE_CLAIM,
};

const ProfileClaim = ({ setIsAosVerification, handleFormType, allSteps }) => {
  const Router = useRouter();
  const track = useTracking();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const userData = useUserData();
  const { navigate } = usePageContext();
  const createRegistrationMutation = useCreateRegistrationMutation({
    onError: error => {
      enqueueSnackbar(errorMessageConverter(error), { variant: SNACKBAR_VARIANTS.ERROR });
      track.actionCallback({
        ...trackingData,
        meta: {
          step: ACTION_CALLBACK_STEPS.CREATE_REGISTRATION,
          actionStatus: ACTION_CALLBACK_STATUS.FAILURE,
          errorMessage: errorMessageConverter(error),
        },
      });
    },
    onSuccess: response => {
      navigate.to({
        href: {
          pathname: BASE_PAGE_ROUTES.REGISTER,
          query: { id: response.data.id, claim: true },
        },
      });
      track.actionCallback({
        ...trackingData,
        meta: {
          step: ACTION_CALLBACK_STEPS.CREATE_REGISTRATION,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
        },
      });
    },
  });

  const createNewProfileHandler = () => {
    const url = { as: Router.asPath };
    const nextPage = url ? url.as : get(Router, `query.${nextPageQueryName}`);
    const payload = registrationPayload({
      userData,
      step: REGISTRATION_STEPS.createProfileExistingUser,
      origin: 'profile-drop-down',
      originLink: nextPage,
      flowId: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
    });
    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.CLAIM_PROFILE,
    });
    createRegistrationMutation.mutate(payload);
  };

  const clickHandlerAosAndAgency = id => {
    setIsAosVerification(true);
    handleFormType(allSteps.WORK_EMAIL_VERIFICATION);
    track.click({
      ...trackingData,
      subComponent: id === 'claimOrg' ? SUB_COMPONENTS.CLAIM_ORGANIZATION : SUB_COMPONENTS.CLAIM_AGENCY,
    });
  };

  const profileClaimOptions = [
    {
      id: 'createProfile',
      title: `${TP}.CLAIM_STEP_PROFILE_TITLE_MAIN`,
      subTitle: `${TP}.CLAIM_STEP_PROFILE_TITLE`,
      onClick: createNewProfileHandler,
    },
    {
      id: 'claimOrg',
      title: `${TP}.CLAIM_STEP_ARTS_ORG_MAIN`,
      subTitle: `${TP}.CLAIM_STEP_ARTS_ORG`,
      onClick: clickHandlerAosAndAgency,
    },
    {
      id: 'claimAgency',
      title: `${TP}.CLAIM_STEP_AGENCY_MAIN`,
      subTitle: `${TP}.CLAIM_STEP_AGENCY`,
      onClick: clickHandlerAosAndAgency,
    },
  ];
  return (
    <div className={classnames(classes.profileClaim)}>
      <div className={classes.profileClaim__header}>
        <Typography size="18" weight="medium" className={classes.getJobInfo__title}>
          {t(`${TP}.CLAIM_MODAL_TITLE`)}
        </Typography>
      </div>
      <div className={classes.profileClaim__options}>
        {profileClaimOptions.map(option => (
          <ActionCard
            key={option.title}
            title={t(option.title)}
            onClick={() => option.onClick(option.id)}
            subTitle={t(option.subTitle)}
            icon="arrow_forward"
            iconSize={16}
            titleSize="14"
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileClaim;
