import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { KEYBOARD_KEY_VALUES } from 'utils/hooks/useOnKeypress';
import classes from './ActionCard.module.scss';

const ActionCard = ({ title, children, onClick, icon, iconSize, className, titleSize, subTitle }) => (
  <div
    className={classnames(classes.actionCard, className)}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={e => e.key === KEYBOARD_KEY_VALUES.ENTER && onClick && onClick(e)}
  >
    <div className={classes.actionCard__content}>
      {title && (
        <Typography size={titleSize} weight="medium" className={classes.actionCard__title}>
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography size={12} color="secondary" className={classes.actionCard__title}>
          {subTitle}
        </Typography>
      )}
      {children && <div className={classes.actionCard__extra}>{children}</div>}
    </div>
    {icon && <SpriteIcon icon={icon} size={iconSize} className={classes.actionCard__icon} />}
  </div>
);

// NOTE: PropTypes for type checking
ActionCard.propTypes = {
  title: PropTypes.string,
  titleSize: PropTypes.number || PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  className: PropTypes.string,
};

// NOTE: Default props
ActionCard.defaultProps = {
  title: '',
  children: null,
  onClick: null,
  icon: 'arrow_forward',
  iconSize: 16,
  titleSize: '14',
  className: '',
};

export default ActionCard;
