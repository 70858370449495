/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';
import Typography from 'components/uiLibrary/Typography';
import Button from 'components/uiLibrary/Button';
import Loader from 'components/uiLibrary/Loader';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';

import classes from './AuthSelector.module.scss';
const SocialMediaForm = dynamic(() => import('components/Globals/Accounts/Registration/SocialMediaForm'), {
  loading: () => <Loader small />,
});

const ExistingEmailVerification = dynamic(() => import('components/Globals/Accounts/Login/ExistingEmailVerification'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});
const signInAuthConfigs = {
  formTitle: `${TP}.FN_LOGIN_REGISTER_AUTH_TITLE`,
  formFooter: { text: `${TP}.FN_LOGIN_POPUP_FOOTER_TEXT`, linkText: `${TP}.FN_LOGIN_POPUP_FOOTER_LINK` },
  bulletTitles: null,
  actionConfigs: [
    {
      variant: 'tertiary',
      size: 'large',
      text: `${TP}.FN_AUTH_LOGIN_OR_REGISTER_EMAIL`,
      icon: 'email_filled',
      buttonType: 'emailVerification',
    },
  ],
};

const AuthSelector = ({
  handleFormType,
  trackingData = {},
  isReturningUser,
  scrollIntoView,
  isModal,
  snackbarShowMessage,
  setCurrentEmail,
  allSteps = {},
  setLoginViaOtp,
  currentEmail,
  setIsModalCloseDisabled,
  setShowUpdateUser,
  isAosVerification = false,
  setAdditionalEmailData,
  whiteListedOrganizations,
  accountSharing,
  isPaywall,
}) => {
  const track = useTracking();
  const { t } = useTranslation('NS_APP_GLOBALS');

  const { formTitle, actionConfigs } = signInAuthConfigs;
  return (
    <div
      className={classnames(classes.authSelector, {
        [classes.authSelector__withPaywall]: isPaywall,
      })}
    >
      {!isPaywall && (
        <div className={classnames(classes.authSelector__header)}>
          <Typography
            size={isModal ? 18 : 24}
            weight="medium"
            className={classnames(classes.authSelector__header_title)}
          >
            {t(formTitle)}
          </Typography>
        </div>
      )}
      {isPaywall && (
        <div>
          <ExistingEmailVerification
            snackbarShowMessage={snackbarShowMessage}
            setCurrentEmail={setCurrentEmail}
            allSteps={allSteps}
            setLoginViaOtp={setLoginViaOtp}
            currentEmail={currentEmail}
            setIsModalCloseDisabled={setIsModalCloseDisabled}
            setShowUpdateUser={setShowUpdateUser}
            isAosVerification={isAosVerification}
            setAdditionalEmailData={setAdditionalEmailData}
            whiteListedOrganizations={whiteListedOrganizations}
            accountSharing={accountSharing}
            handleFormType={handleFormType}
            scrollIntoView={scrollIntoView}
            isPaywall={isPaywall}
            key="authSelector"
          />

          <div className={classes.orSeparator}>
            <hr />
            <Typography size={12} weight="bold">
              or
            </Typography>
            <hr />
          </div>
        </div>
      )}
      <div className={classnames(classes.authSelector__actions)}>
        <SocialMediaForm trackingData={trackingData} isReturningUser={isReturningUser} />
        {!isPaywall &&
          actionConfigs?.map(action => (
            <Fragment key={action.text}>
              <Button
                variant={action.variant}
                className={classnames(classes.authSelector__actions__button)}
                onClick={() => {
                  track.click({
                    ...trackingData,
                    subComponent: SUB_COMPONENTS.CONTINUE_EMAIL_BUTTON,
                    meta: {
                      isReturningUser,
                    },
                  });
                  handleFormType(action.buttonType);
                  scrollIntoView();
                }}
                shape="rectangle"
                isV4
              >
                {action.icon && <SpriteIcon icon={action.icon} />}
                {t(action.text)}
              </Button>
            </Fragment>
          ))}
      </div>
    </div>
  );
};

AuthSelector.propTypes = {
  handleFormType: PropTypes.func,
};

export default AuthSelector;
