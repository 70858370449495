/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';
import DoneIcon from '@mui/icons-material/Done';
import styles from './CheckBox.module.scss';

const CheckBox = ({ checked, onChange, disabled, id = 'checkboxInput', className = {} }) => (
  <span className={classes(styles.checkbox, className, { [styles.disabled]: disabled })}>
    <input
      className={styles.checkboxInput}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      id={id}
    />
    <label htmlFor={id}>
      <DoneIcon />
    </label>
  </span>
);

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckBox;
