import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useRouteChangeHandler = (setIsLoading, onComplete) => {
  const Router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsLoading(true);
      if (onComplete) {
        onComplete();
      }
    };

    const handleRouteChangeComplete = () => {
      setIsLoading(false);
      if (onComplete) {
        onComplete();
      }
    };

    const handleRouteChangeError = () => {
      setIsLoading(false);
    };

    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeComplete);
    Router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
      Router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, [Router, onComplete]);
};

export default useRouteChangeHandler;
