/* eslint-disable import/no-cycle */
import React, { useState, useMemo, useCallback, memo, useRef, useEffect } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';

import useTracking from 'components/Globals/Analytics';
import Image from 'components/uiLibrary/Image';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import { withSnackbar } from 'components/SnackbarNotification';
import Loader from 'components/uiLibrary/Loader';
import AuthSelector from 'components/Globals/Accounts/Common/AuthSelector';
import EmailForm from 'components/Globals/Accounts/Login/EmailForm';
import NewProfessionalEmail from 'components/Globals/Accounts/Registration/NewProfessionalEmail';
import ProfileClaim from 'components/Globals/Accounts/Registration/ProfileClaim';
import { SECTIONS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import orgQueries from 'containers/Organizations/queries';

import { getCookie } from 'utils/cookie';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import useAppContext from 'utils/hooks/useAppContext';
import { TP, LOGIN_SOURCE, STEPS_LOGIN as STEPS, STEP_VALUES, LOGIN_TYPES } from 'constants/index';
import { LOGIN_TYPE_RETURNING } from 'constants/cookieConstants';
import { useTranslation, Trans } from 'src/i18n';

import LogoImage from 'public/images/logo.png';

import classes from './login.module.scss';

const ValidateEmailForm = dynamic(() => import('components/Globals/Accounts/Common/ValidateEmailForm'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});
const ForgotPasswordForm = dynamic(() => import('components/Globals/Accounts/Login/ForgotPasswordForm'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});
const ExistingEmailVerification = dynamic(() => import('components/Globals/Accounts/Login/ExistingEmailVerification'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});
const ReturningSocialAuthUser = dynamic(() => import('components/Globals/Accounts/Login/ReturingSocialAuthUser'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});

const RegistrationUpdate = dynamic(() => import('components/Globals/Accounts/Registration/EmailForm'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});

const DeviceManagement = dynamic(() => import('components/Globals/Accounts/Login/DeviceManagement'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});

const PasswordSetup = dynamic(() => import('components/Globals/Accounts/Common/SetupPassword'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});

const CastingToolAccess = dynamic(() => import('components/Globals/Accounts/Common/CastingToolAccess'), {
  loading: () => (
    <div className={classes.loaderStyle}>
      <Loader small />
    </div>
  ),
});

const trackingData = {
  section: SECTIONS.LOGIN_REGISTER_MODAL,
};

const stepsConfig = [
  {
    id: STEPS.SELECT,
    component: AuthSelector,
    props: {
      trackingData: {
        ...trackingData,
        component: SUB_COMPONENTS.AUTH_SELECTOR,
      },
    },
  },
  {
    id: STEPS.EMAIL_VERIFICATION,
    title: `${TP}.FN_AUTH_TYPE_SIGN_IN_EMAIL_BUTTON`,
    component: ExistingEmailVerification,
    props: {
      trackingData: {
        ...trackingData,
        component: STEPS.EMAIL_VERIFICATION,
      },
    },
  },
  {
    id: STEPS.EMAIL,
    title: `${TP}.FN_AUTH_TYPE_SIGN_IN_EMAIL_BUTTON`,
    component: EmailForm,
    props: {
      trackingData: {
        ...trackingData,
        component: STEPS.EMAIL,
      },
    },
  },
  {
    id: STEPS.VALIDATE,
    title: `${TP}.FN_LOGIN_VIA_SECURITY_CODE_TITLE`,
    component: ValidateEmailForm,
    props: {
      loginViaOtp: true,
      trackingData: {
        ...trackingData,
        component: STEPS.VALIDATE,
      },
    },
  },
  {
    id: STEPS.REGISTRATION_UPDATE,
    title: `${TP}.FN_AUTH_REGISTER_EMAIL_BUTTON`,
    component: RegistrationUpdate,
    props: {
      loginViaOtp: true,
      trackingData: {
        ...trackingData,
        component: STEPS.REGISTRATION_UPDATE,
      },
    },
  },
  {
    id: STEPS.FORGOT_PASSWORD,
    title: `${TP}.FN_FORGOT_PASSWORD_TITLE`,
    component: ForgotPasswordForm,
    props: {
      trackingData: { ...trackingData, component: STEPS.FORGOT_PASSWORD },
    },
  },
  {
    id: STEPS.RETURNING_SOCIAL_AUTH_USER,
    component: ReturningSocialAuthUser,
    props: {
      trackingData: {
        ...trackingData,
        component: STEPS.RETURNING_SOCIAL_AUTH_USER,
      },
    },
  },
  {
    id: STEPS.NEW_PROFESSIONAL_EMAIL,
    component: NewProfessionalEmail,
    props: {
      trackingData: {
        ...trackingData,
        component: STEPS.NEW_PROFESSIONAL_EMAIL,
      },
    },
  },
  {
    id: STEPS.CLAIM_NEW_PROFILE,
    component: ProfileClaim,
    props: {
      trackingData: {
        ...trackingData,
        component: STEPS.CLAIM_NEW_PROFILE,
      },
    },
  },
  {
    id: STEPS.WORK_EMAIL_VERIFICATION,
    component: ExistingEmailVerification,
    props: {
      isAosVerification: true,
      trackingData: {
        ...trackingData,
        component: STEPS.WORK_EMAIL_VERIFICATION,
      },
    },
  },
  {
    id: STEPS.DEVICE_MANAGEMENT,
    component: DeviceManagement,
    props: {
      trackingData: {
        ...trackingData,
        component: STEPS.DEVICE_MANAGEMENT,
      },
    },
  },
  {
    id: STEPS.PASSWORD_SETUP,
    component: PasswordSetup,
    props: {
      trackingData: {
        ...trackingData,
        component: STEPS.PASSWORD_SETUP,
      },
    },
  },
  {
    id: STEPS.CASTING_TOOL_ACCESS,
    component: CastingToolAccess,
    props: {
      trackingData: {
        ...trackingData,
        component: STEPS.CASTING_TOOL_ACCESS,
      },
    },
  },
];

const MainTitleHeader = ({ onClose, hideCloseButton, onBackButton, backButtonShow, t, track, currentStep }) => (
  <div className={classes.login__header}>
    {backButtonShow && (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
      <div className={classes.login__backButton} role="button" onClick={onBackButton}>
        <SpriteIcon icon="arrow_forward" size="20" />
      </div>
    )}

    <div className={classnames(classes.login__header__image)}>
      <Image src={LogoImage} alt="Operabase Home" height={14} width={140} />
      <Typography size="12">{t(`${TP}.LP_SINCE`)}</Typography>
    </div>
    <Typography size="12" className={classnames(classes.login__header_title)}>
      <Trans
        i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
        ns="NS_REGISTRATION_V4"
        components={{
          ul: <ul className={classes.listWorks} />,
          li: <li />,
        }}
      />
    </Typography>
    {hideCloseButton && (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label
      <div
        className={classes.login__closeButton}
        role="button"
        onClick={() => {
          onClose();
          track.click({
            ...trackingData,
            ...currentStep?.props?.trackingData,
            subComponent: SUB_COMPONENTS.CLOSE_CTA,
          });
        }}
      >
        <SpriteIcon icon="close" size="16" />
      </div>
    )}
  </div>
);

const Login = ({
  step = STEP_VALUES[STEPS.SELECT],
  onClose,
  snackbarShowMessage,
  isModal,
  setIsModalCloseDisabled,
  isPaywall = false,
}) => {
  const { isLoginDialog } = useDialogs();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const userData = useUserData();
  const { setPaywallType, isLoggedIn } = useAppContext();
  const loginContainerRef = useRef(null);
  const loginType = getCookie(LOGIN_TYPE_RETURNING);
  const isReturningUser = isLoginDialog.source === LOGIN_SOURCE.RETURNING_USER;
  const isMandatoryName = isLoginDialog.source === LOGIN_SOURCE.MANDATORY_NAME;

  useEffect(() => {
    if (isLoginDialog.isOpen && isLoggedIn) {
      setPaywallType('');
    }
  }, [isLoggedIn, isLoginDialog.isOpen, setPaywallType]);

  const loginStep = useMemo(() => {
    if (isReturningUser && !isPaywall) {
      if ([LOGIN_TYPES.GOOGLE, LOGIN_TYPES.FACEBOOK, LOGIN_TYPES.APPLE].includes(loginType)) {
        return STEP_VALUES[STEPS.RETURNING_SOCIAL_AUTH_USER];
      }
      if ([LOGIN_TYPES.EMAIL, LOGIN_TYPES.OTP].includes(loginType)) {
        return STEP_VALUES[STEPS.EMAIL];
      }
    }
    return step;
  }, [isPaywall, isReturningUser, loginType, step]);

  const [stepIndex, setStepIndex] = useState(loginStep);
  const [currentEmail, setCurrentEmail] = useState('');
  const [loginViaOtp, setLoginViaOtp] = useState(false);
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [isAosVerification, setIsAosVerification] = useState(false);
  const [additionalEmailData, setAdditionalEmailData] = useState({});
  const [accountSharing, setAccountSharing] = useState({});
  const [userId, setUserId] = useState(null);
  const [castingToolPayView, setCastingToolPayView] = useState(false);

  const currentStep = useMemo(() => stepsConfig[stepIndex], [stepIndex]);

  const stepIndexMap = useMemo(
    () => ({
      [STEPS.SELECT]: STEP_VALUES[STEPS.SELECT],
      [STEPS.EMAIL_VERIFICATION]: STEP_VALUES[STEPS.EMAIL_VERIFICATION],
      [STEPS.EMAIL]: STEP_VALUES[STEPS.EMAIL],
      [STEPS.VALIDATE]: STEP_VALUES[STEPS.VALIDATE],
      [STEPS.REGISTRATION_UPDATE]: STEP_VALUES[STEPS.REGISTRATION_UPDATE],
      [STEPS.FORGOT_PASSWORD]: STEP_VALUES[STEPS.FORGOT_PASSWORD],
      [STEPS.RETURNING_SOCIAL_AUTH_USER]: STEP_VALUES[STEPS.RETURNING_SOCIAL_AUTH_USER],
      [STEPS.NEW_PROFESSIONAL_EMAIL]: STEP_VALUES[STEPS.NEW_PROFESSIONAL_EMAIL],
      [STEPS.CLAIM_NEW_PROFILE]: STEP_VALUES[STEPS.CLAIM_NEW_PROFILE],
      [STEPS.WORK_EMAIL_VERIFICATION]: STEP_VALUES[STEPS.WORK_EMAIL_VERIFICATION],
      [STEPS.DEVICE_MANAGEMENT]: STEP_VALUES[STEPS.DEVICE_MANAGEMENT],
      [STEPS.CASTING_TOOL_ACCESS]: STEP_VALUES[STEPS.CASTING_TOOL_ACCESS],
    }),
    [],
  );

  const { data: whiteListedOrganizations, refetch: refetchWhiteListedOrganizations } = useQuery(
    orgQueries.getOrganizations({
      limit: 20,
      filters: {
        email: additionalEmailData?.email || currentEmail || userData?.email,
      },
      queryConfig: {
        select: response => ({
          data: response?.data,
        }),
      },
    }),
  );

  useEffect(() => {
    refetchWhiteListedOrganizations();
  }, [currentEmail, additionalEmailData?.email, refetchWhiteListedOrganizations]);

  const handleBackButton = useCallback(() => {
    track.click({
      ...trackingData,
      ...currentStep?.props?.trackingData,
      subComponent: SUB_COMPONENTS.BACK_CTA,
      meta: { isReturningUser },
    });

    if (isAosVerification) {
      if (castingToolPayView && currentStep.id === STEPS.WORK_EMAIL_VERIFICATION) {
        setStepIndex(stepIndexMap[STEPS.CASTING_TOOL_ACCESS]);
        return;
      }
      if (currentStep.id === STEPS.WORK_EMAIL_VERIFICATION) {
        setStepIndex(stepIndexMap[STEPS.CLAIM_NEW_PROFILE]);
        return;
      }
      if (currentStep.id === STEPS.VALIDATE) {
        setStepIndex(stepIndexMap[STEPS.WORK_EMAIL_VERIFICATION]);
        return;
      }
    }

    if (currentStep.id === STEPS.FORGOT_PASSWORD) {
      setStepIndex(stepIndexMap[STEPS.EMAIL]);
    } else if (currentStep.id === STEPS.REGISTRATION_UPDATE) {
      setStepIndex(stepIndexMap[STEPS.EMAIL_VERIFICATION]);
    } else if (currentStep.id === STEPS.VALIDATE && (loginViaOtp || showUpdateUser)) {
      setStepIndex(stepIndexMap[STEPS.EMAIL_VERIFICATION]);
    } else if (currentStep.id === STEPS.VALIDATE) {
      setStepIndex(stepIndexMap[STEPS.EMAIL]);
    } else if (currentStep.id === STEPS.RETURNING_SOCIAL_AUTH_USER) {
      setStepIndex(stepIndexMap[STEPS.SELECT]);
    } else {
      const previousStepIndex = stepIndexMap[currentStep.id] - 1;
      setStepIndex(previousStepIndex);
    }
  }, [
    track,
    currentStep?.props?.trackingData,
    currentStep?.id,
    isReturningUser,
    isAosVerification,
    castingToolPayView,
    loginViaOtp,
    showUpdateUser,
    stepIndexMap,
  ]);

  const handleFormType = useCallback(
    type => {
      const forgotPasswordStep = stepsConfig.find(stepData => stepData.id === STEPS.FORGOT_PASSWORD);
      if (type === STEPS.FORGOT_PASSWORD && forgotPasswordStep) {
        setStepIndex(stepsConfig.indexOf(forgotPasswordStep));
      } else {
        const index = stepIndexMap[type];
        setStepIndex(index);
      }
    },
    [stepIndexMap],
  );

  const handleSubmit = useCallback(() => {
    const nextStepIndex = stepIndexMap[currentStep.id] + 1;
    setStepIndex(nextStepIndex);
  }, [currentStep, stepIndexMap]);

  const CurrentComponent = currentStep?.component;

  const backButtonShow = useMemo(
    () =>
      ![
        STEPS.SELECT,
        STEPS.NEW_PROFESSIONAL_EMAIL,
        STEPS.CLAIM_NEW_PROFILE,
        STEPS.REGISTRATION_UPDATE,
        STEPS.DEVICE_MANAGEMENT,
        STEPS.CASTING_TOOL_ACCESS,
      ].includes(currentStep?.id),
    [currentStep?.id],
  );

  const hideCloseButton = useMemo(() => {
    if (isReturningUser || !isModal || currentStep?.id === STEPS.VALIDATE || isMandatoryName) {
      return false;
    }
    return true;
  }, [isMandatoryName, isReturningUser, isModal, currentStep?.id]);

  const scrollIntoView = useCallback(() => {
    if (loginContainerRef.current) {
      loginContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loginContainerRef]);

  return (
    <div className={classnames(classes.login, { [classes.loginForPage]: !isModal })} ref={loginContainerRef}>
      {isModal && (
        <MainTitleHeader
          isReturningUser={isReturningUser}
          onBackButton={handleBackButton}
          onClose={onClose}
          isModal={isModal}
          t={t}
          backButtonShow={backButtonShow}
          hideCloseButton={hideCloseButton}
          currentStep={currentStep}
          track={track}
        />
      )}

      {!isModal && backButtonShow && (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
        <div className={classes.page__backBtn} role="button" onClick={handleBackButton}>
          <SpriteIcon icon="arrow_forward" size="18" className={classes.page__backBtn_icon} />{' '}
          <Typography size="12">{t(`${TP}.FN_BACK`)}</Typography>
        </div>
      )}
      <div>
        {CurrentComponent && (
          <CurrentComponent
            {...currentStep.props}
            handleFormType={handleFormType}
            onSubmit={!additionalEmailData.email && handleSubmit}
            currentEmail={currentEmail}
            setCurrentEmail={setCurrentEmail}
            snackbarShowMessage={snackbarShowMessage}
            loginViaOtp={loginViaOtp}
            as={isLoginDialog?.as}
            isReturningUser={isReturningUser}
            allSteps={STEPS}
            setIsModalCloseDisabled={setIsModalCloseDisabled}
            scrollIntoView={scrollIntoView}
            setLoginViaOtp={setLoginViaOtp}
            showUpdateUser={showUpdateUser}
            setShowUpdateUser={setShowUpdateUser}
            isModal={isModal}
            whiteListedOrganizations={whiteListedOrganizations}
            setIsAosVerification={setIsAosVerification}
            isAosVerification={isAosVerification}
            setAdditionalEmailData={setAdditionalEmailData}
            additionalEmailData={additionalEmailData}
            setAccountSharing={setAccountSharing}
            accountSharing={accountSharing}
            setUserId={setUserId}
            userId={userId}
            setCastingToolView={setCastingToolPayView}
            isPaywall={isPaywall}
          />
        )}
      </div>
    </div>
  );
};

export default memo(withSnackbar(Login));
